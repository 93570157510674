import classnames from 'classnames';
import React, { useState } from 'react';
import { Footer } from '../components/footer';

const Datenschutz = () => {
  const [maskTopActive, setMaskTopActive] = useState(false);
  return (
    <div className="page style-2">
      <div className="content">
        <a href="/">zurück zur Startseite</a>
        <h2>Datenschutzbestimmungen</h2>
        <p>
          Der Betreiber dieser Website nimmt Ihren Datenschutz sehr ernst und
          behandelt Ihre personenbezogenen Daten vertraulich und entsprechend
          der gesetzlichen Vorschriften.
        </p>
        <p>
          Die Nutzung unserer Website ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <p>
          Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
        </p>
        <p>
          Philip Mancarella
          <br />
          Benfleetstr. 45
          <br />
          50858 Köln
        </p>
        <p>
          E-Mail:{' '}
          <a href="mailto:info@philipmancarella.de">info@philipmancarella.de</a>
        </p>
        <h3>Cookies</h3>
        Diese Website verwendet keine Cookies.
        <h3>Server-Log-Files</h3>
        Der Provider dieser Website erhebt und speichert automatisch
        Informationen in so genannten Server-Log-Files, die Ihr Browser
        automatisch an uns übermittelt. Diese sind:
        <ul>
          <li>das Datum und Uhrzeit der Anfrage</li>
          <li>die Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
          <li>Ihre derzeit verwendete IP-Adresse</li>
          <li>die URL Ihrer Anfrage</li>
          <li>den Zugriffsstatus/HTTP-Statuscode</li>
          <li>die jeweils übertragene Datenmenge</li>
          <li>die Website, von der die Anforderung kommt (Referrer)</li>
          <li>Ihr Betriebssystem und dessen Oberfläche</li>
          <li>die Sprache und Version der Browsersoftware.</li>
        </ul>
        Diese Daten sind nicht bestimmten Personen zuordenbar und werden
        getrennt von personenbezogenen Daten gespeichert. Eine Zusammenführung
        dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Diese
        Daten werden zur Gefahrenabwehr im Falle von Angriffen auf unser System
        benötigt. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn
        uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
        werden.
        <h3>Kontaktmöglichkeiten</h3>
        Diese Website enthält aufgrund der gesetzlichen Vorschriften
        Kontaktdaten, die es dem Nutzer erlauben, auf einfache Art mit uns in
        Kontakt zu treten. Erfolgt diese Kontaktaufnahme auf elektronischem Weg
        (z.B. per E-Mail), werden diese personenbezogenen Daten bei uns
        automatisch gespeichert. Diese Kontaktdaten übermitteln Sie uns auf
        freiwillger Basis. Die Daten werden nur zum Zweck der Bearbeitung, der
        von Ihnen gewünschten Kontaktaufnahme gespeichert und werden nicht an
        Dritte weitergegeben.
      </div>
      <Footer />
      <div className={classnames('mask top', { active: maskTopActive })}></div>
      <div className="mask bottom active"></div>
    </div>
  );
};

export default Datenschutz;
